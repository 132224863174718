/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@import 'variables';

// /* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

// /* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/flex-utils.css';
// @import "@ionic/angular/css/text-alignment.css";
// @import "@ionic/angular/css/text-transformation.css";

:root {
  --ion-font-family: 'RF Dewi Extended';
}

ion-app {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

.plt-hybrid {
  .mdc-dialog {
    padding-top: env(safe-area-inset-top);
  }
}

ion-progress-bar {
  $bar-height: 8px;

  height: $bar-height;
  border-radius: $bar-height;

  &::part(track) {
    background: map-get($colors, 'gray');
  }
}

ion-list.cards-list-sliding ion-item-sliding {
  border-radius: map-get($elements-border-radius, 'card');

  ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
  }

  .falcon-card {
    width: 100%;
    font-size: 14px;
  }

  &.item-sliding-active-slide {
    .falcon-card {
      border-radius: 0 !important;
    }
  }
}
