@import 'variables';
@import 'utils';

$primary-color: map-get($colors, 'primary');
$secondary-color: map-get($colors, 'secondary');
$pre-white-color: map-get($colors, 'pre-white');
$white-color: map-get($colors, 'white');

$card-border-radius: map-get($elements-border-radius, 'card');

* {
  box-sizing: border-box;

  &:focus {
    outline: none !important;
  }
}

html,
body {
  height: 100%;
}

.font-semibold {
  font-weight: 600 !important;
}

i.falcon-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.page-placeholder-text {
  font-size: 14px;
  line-height: 1.5;
  color: map-get($colors, 'gray');
}

// Disable inputs autozoom on iOS
mat-form-field.mat-mdc-form-field {
  font-size: 16px !important;
}

.official-text {
  font-family: 'Tahoma' !important;
}

textarea {
  resize: none !important;
}

.snack-bar-opened {
  padding-bottom: 4rem;
}

.falcon-card {
  @extend .falcon-elevation, .falcon-card-border-radius !optional;

  display: flex;
  background-color: $white-color;
  padding: 15px;
  position: relative;

  .title {
    font-size: 15px;
    font-weight: bold;
    color: $primary-color;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 600;
    color: map-get($colors, 'gray');
  }

  .actions-block {
    display: flex;
    gap: 10px;

    button[mat-icon-button],
    button[mat-mini-fab] {
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;

      .falcon-icon {
        font-size: 22px;
      }
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 120px;
  height: 30px;
  font-size: 10px;
  font-weight: bold;
  line-height: 1;
  color: currentColor;
  border-radius: map-get($elements-border-radius, 'badge');
  border: 2px solid currentColor;
  position: absolute;
  top: 15px;
  right: 15px;
}

.schedule-table {
  display: grid;
  grid-template-columns: 75px repeat(2, 1fr);
  align-items: baseline;
  color: $primary-color;
  font-weight: bold;
  font-size: 12px;

  .time-period {
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 3px;
  }
}

.list-gap {
  gap: 10px;
}

h1.falcon-form-section-heading {
  margin: -1rem -1rem 1rem;
  padding: 1rem;
  color: $white-color;
  background-color: $primary-color;
}

.mat-mdc-icon-button.mdc-icon-button {
  // Smaller size
  &.sm {
    $sm-button-size: 40px;

    width: $sm-button-size;
    height: $sm-button-size;
    padding: 0;

    .mat-mdc-button-touch-target {
      width: $sm-button-size;
      height: $sm-button-size;
    }
  }

  &.header-action-btn {
    width: 35px;
    height: 35px;
    padding: 0;

    .falcon-icon {
      font-size: 20px;
      line-height: 20px;
    }
  }

  &.stroked {
    border: 1px solid currentColor;
  }
}

.time-selection-status {
  color: $primary-color;
  font-size: 12px;
  margin: 0 1rem;
  border: 1px solid $primary-color;
  border-radius: $card-border-radius;
  position: relative;

  &__hours {
    font-weight: bold;
  }

  &__time {
    font-size: 13px;
    color: map-get($colors, 'gray');

    .falcon-icon {
      display: inline-flex;
      padding: 0 3px;

      &::before {
        line-height: 0;
      }
    }
  }
}

falcon-time,
falcon-edit-contract,
falcon-edit-confirmation,
falcon-flash-time {
  .right-border {
    &::before {
      content: '';
      position: absolute;
      display: block;
      height: calc(50% - 9px);
      top: 0;
      right: 50%;
      border-right: $primary-color 1px solid;
      z-index: 1;
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      height: calc(50% - 37px);
      bottom: 0;
      right: 50%;
      border-right: $primary-color 1px solid;
    }
  }
}

.falcon-contract-header-overview {
  .overview {
    position: relative;
    height: 48px;
  }

  .falcon-icon-clock,
  .falcon-icon-coffee,
  .falcon-icon-arrow-right {
    display: inline-block;
    margin: 0 0.25rem;

    &::before {
      line-height: 0;
    }
  }

  .mat-expansion-panel {
    position: absolute;
    top: 0;
    z-index: 10;
    width: 100%;
    margin-top: 0.5rem;

    &.mat-expanded {
      background-color: $white-color;
    }

    &:not(.mat-expanded) {
      box-shadow: unset;
      margin-top: unset;
      background-color: $pre-white-color;

      .mat-expansion-panel-header:hover {
        background-color: $pre-white-color;
      }
    }
  }

  .mat-expansion-panel-body {
    overflow: auto;
    max-height: calc(100vh - 228px);

    @media (max-width: $mobile-s-width) {
      max-height: calc(100vh - 184px);
    }
  }
}

.plt-desktop {
  $mobile-like-width: 425px;

  ion-app {
    width: 100%;
    max-width: $mobile-like-width;
    left: 50%;
    transform: translateX(-50%);
  }
  .mat-mdc-dialog-surface {
    max-width: $mobile-like-width !important;
  }
}

.plt-hybrid {
  ion-app {
    background-color: $primary-color;
  }
}
