// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #5d06e8;
  --ion-color-primary-rgb: 93, 6, 232;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #5205cc;
  --ion-color-primary-tint: #6d1fea;

  --ion-color-secondary: #a3fb31;
  --ion-color-secondary-rgb: 163, 251, 49;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #8fdd2b;
  --ion-color-secondary-tint: #acfb46;

  --ion-color-tertiary: #ff1c87;
  --ion-color-tertiary-rgb: 255, 28, 135;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #e01977;
  --ion-color-tertiary-tint: #ff3393;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #d47100;
  --ion-color-warning-rgb: 212, 113, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #bb6300;
  --ion-color-warning-tint: #d87f1a;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-medium: #8c8c8c;
  --ion-color-medium-rgb: 140, 140, 140;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #7b7b7b;
  --ion-color-medium-tint: #989898;

  --ion-color-light: #fafafa;
  --ion-color-light-rgb: 250, 250, 250;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dcdcdc;
  --ion-color-light-tint: #fbfbfb;
}
