@import 'variables';

$primary-color: map-get($colors, 'primary');
$secondary-color: map-get($colors, 'secondary');
$warning-color: map-get($colors, 'warning');
$error-color: map-get($colors, 'error');
$white-color: map-get($colors, 'white');
$pre-white-color: map-get($colors, 'pre-white');
$gray-color: map-get($colors, 'gray');
$gray-bg-color: map-get($colors, 'gray-bg');
$gray-text-color: map-get($colors, 'gray-text');

$button-border-radius: map-get($elements-border-radius, 'button');
$card-border-radius: map-get($elements-border-radius, 'card');

// Buttons
.mat-mdc-button-base {
  letter-spacing: normal;

  .mdc-button__label {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.mat-mdc-outlined-button:not(:disabled) {
    border-color: currentColor;
  }

  &.mat-accent:not(:disabled) .mdc-button__label {
    color: $primary-color;
  }

  &.falcon-rounded-btn {
    @extend .text-wrap !optional;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    font-size: 1rem;
    font-weight: 600;
    position: relative;
    border-radius: $button-border-radius;
    line-height: 1.25;
  }
}

// Form field
.mat-mdc-form-field.mat-form-field-appearance-outline {
  .mdc-notched-outline {
    z-index: -1;

    &__leading,
    &__notch,
    &__trailing {
      background-color: $white-color;
    }
  }

  .mat-mdc-form-field-text-suffix {
    background-color: $white-color;
    padding-left: 5px;
  }
}

// Tabs
.mat-mdc-tab-header .mat-mdc-tab-list {
  $tab-height: 40px;

  border: 1px solid $primary-color;
  border-radius: $card-border-radius;
  overflow: hidden;

  .mdc-tab {
    height: $tab-height;
    min-width: auto;
    padding: 0;

    &:not(:first-child) {
      border-left: 1px solid $primary-color;
    }

    &__text-label {
      color: $primary-color;
      font-weight: bold;
    }

    &--active .mdc-tab__content .mdc-tab__text-label {
      color: $white-color !important;
    }

    .mdc-tab-indicator .mdc-tab-indicator__content--underline {
      border-top-width: $tab-height;
    }

    &__ripple {
      display: none;
    }
  }
}

.mat-mdc-tab-group {
  .mat-mdc-tab-header {
    margin: 0 1rem;
  }

  .mdc-tab {
    flex-basis: 0;
  }
}

falcon-nav-tabs.company-nav-tabs,
falcon-nav-tabs.employee-user-nav-tabs {
  .mat-mdc-tab-header .mat-mdc-tab-list {
    $tab-height: 65px;

    border: 0;
    border-radius: 0;

    .mdc-tab {
      height: $tab-height;
      font-size: 11px;
      flex: 1;
      border: 0;

      .mdc-tab__content .mdc-tab__text-label {
        flex-direction: column;
        color: $primary-color;
        font-weight: bold;

        .falcon-icon {
          font-size: 20px;
          margin-bottom: 4px;

          &-contracts {
            font-size: 30px;
            height: 20px;
          }

          .mat-badge-content {
            font-style: normal;
            letter-spacing: normal;
          }
        }
      }

      .mdc-tab-indicator .mdc-tab-indicator__content--underline {
        border-top-width: $tab-height;
        border-radius: $button-border-radius $button-border-radius 0 0;
      }

      &:first-child .mdc-tab-indicator .mdc-tab-indicator__content--underline {
        border-top-left-radius: 0;
      }

      &:last-child .mdc-tab-indicator .mdc-tab-indicator__content--underline {
        border-top-right-radius: 0;
      }
    }
  }
}

// Calendars
.mat-calendar-table {
  .mat-calendar-body-cell-content {
    font-weight: 600;
    color: $primary-color;
    border-radius: $button-border-radius;
  }
  .mat-calendar-body-label {
    padding: 0 !important;
    visibility: hidden;
  }
}

.existing-contract-date {
  .mat-calendar-body-cell-preview {
    top: 5%;
    left: 5%;
    height: 90%;
    width: 90%;
    background-color: $gray-color;
    border-radius: $button-border-radius;
  }

  &-start,
  &-in-range,
  &-end {
    .mat-calendar-body-cell-preview {
      top: 5%;
      left: 0;
      height: 90%;
      width: 100%;
      background-color: $gray-color;
    }
  }

  &-start .mat-calendar-body-cell-preview {
    border-top-left-radius: $button-border-radius;
    border-bottom-left-radius: $button-border-radius;
  }

  &-end .mat-calendar-body-cell-preview {
    border-top-right-radius: $button-border-radius;
    border-bottom-right-radius: $button-border-radius;
  }
}

.mat-calendar-table-header th {
  padding: unset !important;

  & .mat-calendar-abbr {
    text-transform: uppercase;
    text-decoration: none;
  }
}

.mat-calendar-body-cell {
  .mat-calendar-body-selected {
    color: $white-color;
    background-color: $primary-color;
  }

  &.mat-calendar-body-in-range::before {
    $border-in-range: 1px solid $primary-color;

    border-top: $border-in-range;
    border-bottom: $border-in-range;
    background-color: transparent;
  }

  &.mat-calendar-body-range-start::before,
  &.mat-calendar-body-range-end::before {
    background-color: $primary-color;
    border-radius: $button-border-radius;
  }

  &.mat-calendar-body-range-start::before {
    border-radius: $button-border-radius 0 0 $button-border-radius !important;
  }

  &.mat-calendar-body-range-end::before {
    border-radius: 0 $button-border-radius $button-border-radius 0 !important;
  }

  &.invalid-selection {
    .mat-calendar-body-selected,
    &.mat-calendar-body-in-range::before {
      background-color: transparent;
      color: $primary-color;
      border: 2px solid $error-color;
      z-index: 1;
    }

    &.mat-calendar-body-in-range::before {
      border-right: 0;
      border-left: 0;
    }

    &.mat-calendar-body-range-start .mat-calendar-body-cell-content {
      border-right: 0;
    }

    &.mat-calendar-body-range-end .mat-calendar-body-cell-content {
      border-left: 0;
    }
  }
}

falcon-single-date-picker {
  .existing-contract-date {
    &,
    &-start,
    &-in-range,
    &-end {
      .mat-calendar-body-selected {
        background-color: transparent;
        border: 2px solid $error-color;
        color: initial;
      }
    }
  }
}

falcon-date-range-picker {
  .mat-calendar-body-cell:not(.mat-calendar-body-range-end) .mat-calendar-body-selected {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .mat-calendar-body-cell.mat-calendar-body-range-end .mat-calendar-body-selected {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

// Dialog
.mdc-dialog.mat-mdc-dialog-container {
  .mat-mdc-dialog-surface {
    padding: 1rem;
    border-radius: $card-border-radius;
    overflow: hidden;

    .mat-mdc-dialog-content {
      margin: 0.5rem -1rem;
      font-family: inherit;
      font-size: inherit;
    }
  }
}

.full-screen-dialog .mdc-dialog.mat-mdc-dialog-container {
  width: 100%;
  height: 100%;

  .mat-mdc-dialog-surface {
    border-radius: 0;
    padding: 0;
    background-color: $pre-white-color;
  }
}

// Snackbar
.mdc-snackbar.mat-mdc-snack-bar-container {
  margin: 1rem;

  .mdc-snackbar__surface {
    background-color: $white-color;
    border-radius: $card-border-radius;

    .mat-mdc-snack-bar-label {
      color: $primary-color;
      font-weight: 600;
    }

    .mdc-snackbar__actions .mdc-button {
      color: $white-color;
      background-color: $primary-color;
      border-radius: $button-border-radius;
    }
  }

  &.falcon-form-save-action-panel .mdc-snackbar__surface {
    margin: 0;
    border-radius: 0;
  }
}

// Button toggle group
.mat-button-toggle-group-appearance-standard {
  .mat-button-toggle-checked {
    color: $white-color;
    background-color: $primary-color;
  }
}

// List
.mdc-list.sheet-actions-group {
  padding: 0;
  border-radius: $button-border-radius;
  border: 1px solid $primary-color;

  .mdc-list-item {
    text-align: center;

    .mdc-list-item__primary-text {
      color: $primary-color;
      font-weight: 600;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $primary-color;
    }
  }
}

// Progress spinner
.mat-mdc-progress-spinner {
  &.container-absolute-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// Bottom Sheet
.mat-bottom-sheet-container {
  padding: 16px !important;
  border-radius: $card-border-radius $card-border-radius 0 0;
}

// Links
a {
  text-decoration: underline;
  color: inherit;

  &:hover {
    color: inherit;
  }
}

// Button Toggle
.mat-button-toggle-vertical {
  @extend .border-0, .overflow-auto, .px-3, .list-gap !optional;

  margin: 0 -1rem;

  .mat-button-toggle {
    @extend .border-0, .bg-transparent !optional;

    &-button {
      border-radius: $button-border-radius;
      border: 1px solid $primary-color;
      color: $primary-color;
      font-size: 1rem;
      font-weight: 600;

      &:disabled {
        color: $gray-text-color;
        border-color: $gray-text-color;

        &::after {
          display: none;
        }
      }

      .mat-button-toggle-label-content {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.25;
        white-space: normal;
        padding: 0 2.5rem;
        min-height: 48px;
      }

      &::after {
        content: '\f103';
        display: inline-flex;
        font-family: falcon-icons;
        font-size: 1.5rem;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &-ripple {
      border-radius: $button-border-radius;
    }

    &-checked .mat-button-toggle-button {
      background-color: $primary-color;
      color: $white-color;

      &::after {
        content: '\f10b';
      }
    }
  }
}

// Expansion panel
.mat-accordion .mat-expansion-panel-body {
  padding: 0 1rem 1rem;
}

// Select (fix devices with small screen)
.mat-mdc-select-panel .mat-mdc-option .mdc-list-item__primary-text {
  flex-grow: 1;
}
