$colors: (
  'primary': #5d06e8,
  'secondary': #a3fb31,
  'pink': #ff1c87,
  'gray': #a8a8a8,
  'pre-white': #fafafa,
  'error': #fe3e2f,
  'warning': #d47100,
  'black': #1d1d1b,
  'white': #ffffff,
  'gray-bg': #c6c6c6,
  'gray-text': #8c8c8c,
);

$elements-border-radius: (
  'card': 12px,
  'button': 8px,
  'badge': 6px,
);

$basis: (
  '10': 10%,
  '45': 45%,
  '50': 50%,
  '75': 75%,
  '100': 100%,
);

// Mobile S
$mobile-s-width: 321px;
$mobile-s-height: 550px;

// Mobile M
$mobile-m-width: 376px;
$mobile-m-height: 668px;

// Mobile L
$mobile-l-width: 426px;
$mobile-l-height: 845px;
