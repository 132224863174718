@import 'variables';

@each $colorName, $color in $colors {
  .falcon-bg-#{$colorName} {
    background-color: $color !important;
  }
  .falcon-color-#{$colorName} {
    color: $color !important;
  }
}

@each $basisName, $basis in $basis {
  .flex-basis-#{$basisName} {
    flex-basis: $basis;
  }
}

@each $elementType, $borderRadiusSize in $elements-border-radius {
  .falcon-#{$elementType}-border-radius {
    border-radius: $borderRadiusSize !important;
  }
}

.falcon-elevation {
  box-shadow: 0px 1px 10px rgba(43, 47, 119, 0.15);
}
