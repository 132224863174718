@font-face {
  font-family: 'RF Dewi Extended';
  src: url('RFDewiExtended-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RF Dewi Extended';
  src: url('RFDewiExtended-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'RF Dewi Extended';
  src: url('RFDewiExtended-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
