@font-face {
    font-family: "falcon-icons";
    src: url("./falcon-icons.eot?567ef9b00d241e818a142a3e273d9bee#iefix") format("embedded-opentype"),
url("./falcon-icons.woff2?567ef9b00d241e818a142a3e273d9bee") format("woff2"),
url("./falcon-icons.woff?567ef9b00d241e818a142a3e273d9bee") format("woff");
}

i[class^="falcon-icon-"]:before, i[class*=" falcon-icon-"]:before {
    font-family: falcon-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.falcon-icon-add-contract:before {
    content: "\f101";
}
.falcon-icon-add-person:before {
    content: "\f102";
}
.falcon-icon-add:before {
    content: "\f103";
}
.falcon-icon-arrow-left:before {
    content: "\f104";
}
.falcon-icon-arrow-right:before {
    content: "\f105";
}
.falcon-icon-block:before {
    content: "\f106";
}
.falcon-icon-briefcase:before {
    content: "\f107";
}
.falcon-icon-calendar_month:before {
    content: "\f108";
}
.falcon-icon-cancel:before {
    content: "\f109";
}
.falcon-icon-check_circle:before {
    content: "\f10a";
}
.falcon-icon-check:before {
    content: "\f10b";
}
.falcon-icon-chevron-left:before {
    content: "\f10c";
}
.falcon-icon-chevron-right:before {
    content: "\f10d";
}
.falcon-icon-clock:before {
    content: "\f10e";
}
.falcon-icon-close:before {
    content: "\f10f";
}
.falcon-icon-coffee:before {
    content: "\f110";
}
.falcon-icon-contact:before {
    content: "\f111";
}
.falcon-icon-contracts:before {
    content: "\f112";
}
.falcon-icon-copy-content:before {
    content: "\f113";
}
.falcon-icon-domain:before {
    content: "\f114";
}
.falcon-icon-double-check:before {
    content: "\f115";
}
.falcon-icon-edit-company:before {
    content: "\f116";
}
.falcon-icon-edit:before {
    content: "\f117";
}
.falcon-icon-email:before {
    content: "\f118";
}
.falcon-icon-euro:before {
    content: "\f119";
}
.falcon-icon-exclamation_circle:before {
    content: "\f11a";
}
.falcon-icon-expand-less:before {
    content: "\f11b";
}
.falcon-icon-expand-more:before {
    content: "\f11c";
}
.falcon-icon-flash:before {
    content: "\f11d";
}
.falcon-icon-hand:before {
    content: "\f11e";
}
.falcon-icon-info_filled:before {
    content: "\f11f";
}
.falcon-icon-info_outlined:before {
    content: "\f120";
}
.falcon-icon-phone:before {
    content: "\f121";
}
.falcon-icon-place:before {
    content: "\f122";
}
.falcon-icon-reload:before {
    content: "\f123";
}
.falcon-icon-remove:before {
    content: "\f124";
}
.falcon-icon-reset:before {
    content: "\f125";
}
.falcon-icon-search-company:before {
    content: "\f126";
}
.falcon-icon-search:before {
    content: "\f127";
}
.falcon-icon-share:before {
    content: "\f128";
}
.falcon-icon-team:before {
    content: "\f129";
}
.falcon-icon-user-edit:before {
    content: "\f12a";
}
.falcon-icon-warning_circle:before {
    content: "\f12b";
}
.falcon-icon-warning_triangle:before {
    content: "\f12c";
}
